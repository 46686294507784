import Gallery from 'react-photo-gallery';
import React from 'react';
import Carousel from 'react-multi-carousel';

function generateMosaic(props) {
	const array = [];
	props.map((c) => {
		const foto = new Image();
		foto.src = c.catalogImgVO.description;
		document.images[0].src = foto.src;
		const frame = {
			src: c.catalogImgVO.description,
			width: 16,
			height: 9
		};
		array.push(frame);
		return null;
	});

	const gallery = [];
	array.forEach((photoFrame, index) => {
		gallery.push(
			<Gallery key={index} photos={[photoFrame]} direction={'row'} />
		);
	});

	return gallery;
}

const Mosaic = ({ props }) => {
	return (
		<Carousel responsive={responsive} infinite={true} autoPlay={true}>
			{generateMosaic(props).map((e) => e)}
		</Carousel>
	);
};

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 6
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 6
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};

export default Mosaic;
