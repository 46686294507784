import React from 'react';
import { withRouter } from 'react-router-dom';
import './StyleHome.css';

class BusinessCard extends React.Component {
	go2business = (e) => {
		this.props.history.push({
			pathname: '/business',
			data: e
		});

		const aux = {
			id: e.id,
			name: e.name,
			slogan: e.slogan,
			description: e.description,
			phone: e.phone,
			contacEmail: e.contacEmail,
			googlemaps: e.googlemaps,
			instagramLink: e.instagramLink,
			facebookLink: e.facebookLink,
			twitterLink: e.twitterLink,
			mainSociamedia: e.mainSociamedia,
			catalogImgVO: e.catalogImgVO,
			logoLink: e.logoLink
		};
		sessionStorage.setItem('info', JSON.stringify(aux));
	};

	render() {
		return (
			<>
				<div
					className='business-card shadow hover-div'
					key={this.props.c.id}
					onClick={() => this.go2business(this.props.c)}
				>
					<img
						className='business-img'
						src={
							this.props.c.logoLink !== ''
								? this.props.c.logoLink.replace('banner', 'profile')
								: 'https://static.thenounproject.com/png/4381137-200.png'
						}
						alt={this.props.c.name}
					/>
					<div className='h6 text-dark pl-2 mt-2'>
						{this.props.c.name}
						{this.props.c.totalCupones > 0 ? (
							' (' + this.props.c.totalCupones + ')'
						) : (
							<span></span>
						)}
					</div>
				</div>
			</>
		);
	}
}
export default withRouter(BusinessCard);
