import React from 'react';
import { Utils } from '../../resources/Utils';
import { withRouter, Link } from 'react-router-dom';
import { RemoveRedEye } from '@material-ui/icons';
import { LoginController } from '../controller/LoginController';
import { Preload } from '../../utils/Preload';

class LoginComponent extends React.Component {
	constructor() {
		super();
		this.loginController = new LoginController();
		this.state = {
			password: '',
			identifier: ''
		};
	}

	componentDidMount() {
		if (sessionStorage.getItem('role')) {
			this.props.history.push('/superAdminMenu');
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
	};

	loaderFn = async () => {
		await this.loginController.handleLogin(
			this.state.identifier,
			this.state.password
		);
		this.handleSession();
	};

	handleSession() {
		if (sessionStorage.getItem('role') !== null) {
			this.props.history.push('/superAdminMenu');
			window.location.reload(true);
		} else {
			Utils.swalError('Usuario/correo o contraseña incorrectos.');
		}
	}

	mostrarContrasena(event) {
		event.preventDefault();
		const tipo = document.getElementById('password');
		if (tipo.type === 'password') {
			tipo.type = 'text';
		} else {
			tipo.type = 'password';
		}
	}

	render() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-6 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header bg-dark text-white'>
								<h3 className='text-center'>Inicio de Sesión</h3>
							</div>

							<div className='card-body'>
								<form onSubmit={this.handleSubmit}>
									<div className='col-12'>
										<div className='form-group'>
											<label htmlFor='identifier'>Usuario:</label>
											<input
												className='form-control'
												type='text'
												name='identifier'
												id='identifier'
												value={this.state.identifier}
												onChange={this.handleChange}
												placeholder='Usuario'
												required
											/>
										</div>
									</div>

									<div className='col-12'>
										<div className='form-group'>
											<label htmlFor='password'>Contraseña:</label>
											<div className='input-group'>
												<input
													className='form-control'
													type='password'
													name='password'
													id='password'
													value={this.state.password}
													onChange={this.handleChange}
													placeholder='Contraseña'
													required
												/>
												<div className='input-group-append'>
													<button
														id='show_password'
														className='btn btn-primary'
														onClick={this.mostrarContrasena}
													>
														<RemoveRedEye />
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className='float-md-right mt-3'>
										<Link to={'/forgotten'} className='text-right text-primary'>
											Olvidé mi Contraseña
										</Link>
									</div>
									<br></br>
									<div className='mt-4'>
										<Preload
											type='click'
											callToService={this.loaderFn}
											callback={() => null}
										>
											<input
												type='submit'
												value='Iniciar'
												className='btn btn-success btn-md btn-block'
											/>
										</Preload>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(LoginComponent);
