import React from 'react';

const Field = (props) => {
	return (
		<div className='form-group row px-3'>
			<label> Porcentaje de Descuento: </label>
			<input
				className='form-control'
				type='number'
				name={`discount-${props.name}`}
				max='100'
				min='1'
				placeholder='Porcentaje de Descuento'
				required
				onChange={props.handleChange}
			/>
		</div>
	);
};
export default Field;
