import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { Verify } from '../../utils/Verify';
import { CuponGridController } from '../../CuponGrid/controller/CuponGridController';

class CuponEditFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.cuponGridController = new CuponGridController();
		this.verify = new Verify();
		this.state = {
			view: 0,
			forme: {
				description: '',
				lifes: '',
				expirationDate: ''
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}

		if (!this.verify.securityVerify('admin')) {
			this.props.history.push('/superAdminMenu');
		}

		this.actualForm();
	}

	handleChange = (e) => {
		this.setState({
			forme: {
				...this.state.forme,
				[e.target.name]: e.target.value
			}
		});
	};

	back = (_) => {
		if (this.state.view === 0) {
			window.location.reload(true);
		} else {
			window.history.back();
		}
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	actualForm() {
		if (this.props.form) {
			this.setState({ view: 0 });
			this.props.form.expirationDate =
				this.props.form.expirationDate.substring(6, 10) +
				'-' +
				this.props.form.expirationDate.substring(3, 5) +
				'-' +
				this.props.form.expirationDate.substring(0, 2);
			this.setState({ forme: this.props.form });
		} else {
			this.setState({ view: 1 });
		}
	}

	fechaMin = () => {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (month < 10) {
			if (day < 10) return `${year}-0${month}-0${day}`;
			else return `${year}-0${month}-${day}`;
		} else {
			if (day < 10) return `${year}-${month}-0${day}`;
			else return `${year}-${month}-${day}`;
		}
	};

	edit = async (event) => {
		event.preventDefault();
		if (this.state.view === 0) {
			await this.cuponGridController.edit(this.state.forme);
			Utils.swalSuccess('¡Se editó el cupón con éxito!');
			window.location.reload(true);
		}
	};

	parseDate = (date) => {
		const cadena =
			date.substring(6, 10) +
			'-' +
			date.substring(3, 5) +
			'-' +
			date.substring(0, 2);
		return cadena;
	};

	renderFormEdit() {
		return (
			<table className='table table-dark table-striped'>
				<tbody>
					<tr>
						<td>
							<label>Descripción:</label>
							<input
								className='form-control'
								type='text'
								maxLength={300}
								placeholder={this.state.forme.description}
								readOnly
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Vidas:</label>
							<input
								className='form-control'
								type='number'
								name='lifes'
								max='100'
								min='1'
								placeholder='Vidas'
								onChange={this.handleChange}
								value={this.state.forme.lifes}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Fecha de Expiración:</label>
							<input
								className='form-control'
								type='date'
								name='expirationDate'
								onChange={this.handleChange}
								min={this.fechaMin()}
								data-date-format='dd-MM-yyyy'
								value={this.state.forme.expirationDate}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<input
								className='btn btn-primary btn-block'
								type='submit'
								value='Cambiar Datos'
							/>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className='container h-100 py-md-4 py-lg-4 py-sm-2 py-xl-5'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'> Edición de Cupón</h4>
							</div>

							<div className='card-body'>
								<div className='container'>
									<div className='table-responsive'>
										<form onSubmit={this.edit}>{this.renderFormEdit()}</form>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(CuponEditFormComponent);
