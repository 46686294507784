import React, { Component } from 'react';
export class Preload extends React.Component {
	render() {
		return (
			<Drawing
				style={this.props.style}
				callback={this.props.callback}
				type={this.props.type}
				fn={this.props.callToService}
			>
				{this.props.children}
			</Drawing>
		);
	}
}
class Drawing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			wasLoaded: false
		};
	}

	componentDidMount() {
		if (this.props.type === 'load') this.handleAnyFunction();
	}

	handleAnyFunction = () => {
		this.setState({ wasLoaded: true });
		const dataresponse = this.props.fn();
		dataresponse.then(() => {
			this.setState({ wasLoaded: false });
		});
	};

	// envolver el children jxf para agregar el evento y luego también necesitamos el tipo de carga por clic o por carga
	render() {
		return (
			<>
				{this.props.type === 'click' ? (
					<div
						className='skeletonloadbox'
						onClick={this.handleAnyFunction}
						style={this.props.style}
					>
						{this.props.children}
						{this.state.wasLoaded ? (
							<div className='shadowLayer'>
								<div className='spinner rounded-circle'></div>
							</div>
						) : (
							<> </>
						)}
					</div>
				) : this.props.type === 'load' ? (
					<div className='skeletonloadbox' style={this.props.style}>
						{this.props.children}
						{this.state.wasLoaded ? (
							<div className='shadowLayer'>
								<div className='spinner rounded-circle'></div>
							</div>
						) : (
							<> </>
						)}
					</div>
				) : (
					<></>
				)}
			</>
		);
	}
}
