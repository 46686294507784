import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class BusinessGridController {
	async init(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findAll,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit
			}
		});
		return res;
	}

	async findCarrusel(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findCarrusel,
			params: {
				page: tableData.start,
				limit: tableData.limit
			}
		});
		return res;
	}

	async findFilter(tableData) {
		const res = await Client.GET({
			url: Urls.businessApi.findFilter,
			params: {
				page: tableData.page,
				limit: tableData.limit,
				text: tableData.text,
				column: tableData.column
			}
		});
		return res;
	}

	async delete(id) {
		await Client.GET({
			url: Urls.businessApi.delete,
			alert: 'show',
			params: {
				id: id
			}
		});
	}

	async active(id) {
		await Client.POST({
			url: Urls.businessApi.update,
			alert: 'show',
			params: {
				id: id
			},
			data: {
				idB: id,
				status: '1'
			}
		});
	}

	async edit(form) {
		await Client.POST({
			url: Urls.businessApi.update,
			alert: 'show',
			params: {
				id: form.id
			},
			data: {
				name: form.name,
				slogan: form.slogan,
				phone: form.phone,
				email: form.email
			}
		});
	}
}
