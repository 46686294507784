import React, { useState } from 'react';
import '../../resources/css/General.css';

const AlertasCuponInc = () => {
	const [toggle, setToggle] = useState(false);
	return (
		<div>
			<div
				onMouseOver={() => setToggle(!toggle)}
				onMouseOut={() => setToggle(!toggle)}
				className='btn btn-primary btnAlerta'
			>
				?
			</div>

			{toggle && (
				<div className='alerta' style={{ height: 'auto' }}>
					<p className='letraAlerta'>
						Permite que los cupones vayan incrementando su descuento.
					</p>
				</div>
			)}
		</div>
	);
};

export default AlertasCuponInc;
