import React from 'react';
import { withRouter } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Home } from '@material-ui/icons';
import ColorSelect from '../../resources/ColorSelect';
import { QrFormController } from '../Controller/QrFormController';
import { Utils } from '../../resources/Utils';
import BusinessSelector from '../../resources/BusinessSelector';
import { Verify } from '../../utils/Verify';
import { Preload } from '../../utils/Preload';

class QrFormComponent extends React.Component {
	constructor() {
		super();
		this.qrFormController = new QrFormController();
		this.verify = new Verify();
		this.state = {
			otherVariable: 0,
			action: '',
			form: {
				code: '#cuponealo.com.mx#',
				corner: 0,
				eye: 0,
				pattern: '',
				border: 'si',
				gradient: '',
				titleFont: 'HebertSans',
				textFont: 'Averia',
				centerColor: [0, 0, 0],
				backgroundColor: [73, 73, 73],
				cornerColor: [36, 36, 36],
				textColor: [0, 0, 0],
				titleColor: [0, 0, 0],
				platform: 'React',
				idBusiness: sessionStorage.getItem('idBusiness') || ''
			},
			color: {},
			types: {
				corner: [
					{
						id: '',
						description: ''
					}
				],
				eye: [
					{
						id: '',
						description: ''
					}
				],
				pattern: [
					{
						id: '',
						description: ''
					}
				],
				gradient: [
					{
						id: '',
						description: ''
					}
				],
				title: [
					{
						id: '',
						description: ''
					}
				],
				text: [
					{
						id: '',
						description: ''
					}
				]
			}
		};

		this.handleChangeBus = this.handleChangeBus.bind(this);
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload();
		}

		if (!this.verify.securityVerify('admin')) {
			this.props.history.push('/superAdminMenu');
		}

		this.loadTypes();
		setTimeout(() => {
			this.businessStatus();
		}, 500);
	}

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	async businessStatus() {
		const res = await this.qrFormController.findQrBusiness(
			sessionStorage.getItem('idBusiness')
		);

		if (res.data.id === -1) {
			setTimeout(() => {
				Utils.swalError(
					'No se a encontrado una configuración para su QR\nPorfavor cree una.'
				);
			}, 600);

			this.createQr();
			this.setState({ action: 'insert' });
		} else {
			this.setState({ form: res.data });
			this.setData();

			const qrCreated = await this.qrFormController.getPreview(res.data);
			this.previewImage(qrCreated);

			this.setState({ action: 'update' });
		}
	}

	async loadTypes() {
		const type = await this.qrFormController.findAllTypes();
		if (type.data) {
			this.setState({ types: type.data });
		} else {
			Utils.swalError('No se pudo recuperar la información de negocios.');
		}
	}

	setData = (_) => {
		document.getElementById('corner').value = this.state.form.corner;
		document.getElementById('eye').value = this.state.form.eye;
		document.getElementById('pattern').value = this.state.form.pattern;
		document.getElementById('gradient').value = this.state.form.gradient;

		if (this.state.form.border === 'no') {
			document.getElementById('borderNo').checked = true;
			document.getElementById('borderSi').checked = false;
		} else {
			document.getElementById('borderSi').checked = true;
			document.getElementById('borderNo').checked = false;
		}
	};

	handleChange = (e) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	};

	handleChangeCc = (value) => {
		this.setState({
			form: {
				...this.state.form,
				centerColor: [parseInt(value.r), parseInt(value.g), parseInt(value.b)]
			}
		});
		this.verify.verifyQrColor(
			this.state.form.centerColor,
			this.state.form.backgroundColor,
			this.state.form.cornerColor
		);
	};

	handleChangeCf = (value) => {
		this.setState({
			form: {
				...this.state.form,
				backgroundColor: [
					parseInt(value.r),
					parseInt(value.g),
					parseInt(value.b)
				]
			}
		});
		this.verify.verifyQrColor(
			this.state.form.centerColor,
			this.state.form.backgroundColor,
			this.state.form.cornerColor
		);
	};

	handleChangeCe = (value) => {
		this.setState({
			form: {
				...this.state.form,
				cornerColor: [parseInt(value.r), parseInt(value.g), parseInt(value.b)]
			}
		});
		this.verify.verifyQrColor(
			this.state.form.centerColor,
			this.state.form.backgroundColor,
			this.state.form.cornerColor
		);
	};

	handleChangeInt = (e) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: parseInt(e.target.value)
			}
		});
	};

	handleChangeBus = (idB) => {
		this.setState(
			{
				form: {
					...this.state.form,
					idBusiness: idB
				}
			},
			() => {
				window.location.reload();
			}
		);
	};

	createQr = () => {
		document.getElementById('qrPanel').src =
			'https://softitlan-storage.s3.us-east-2.amazonaws.com/images/cuponealo-crearqr.png';
	};

	async previewImage(url) {
		if (url.type !== 'image/png') {
			Utils.swalError(
				'Ah ocurrido un error al generar el QR! Asegurese de ingresar correctamente los datos.'
			);
			this.createQr();
		} else {
			document.getElementById('qrPanel').src = URL.createObjectURL(url);
		}
	}

	async getPicture() {
		document.getElementById('previewButton').innerHTML =
			"<i class='fa fa-spinner fa-spin' />";
		const resp = await this.qrFormController.getPreview(this.state.form);
		this.previewImage(resp).then(
			(document.getElementById('previewButton').innerHTML = 'Previsualizar')
		);
	}

	handleSubmit = async (event) => {
		if (this.state.action === 'insert') {
			await this.qrFormController.insert(this.state.form);
			window.location.reload();
		} else {
			await this.qrFormController.update(this.state.form);
			window.location.reload();
		}
	};

	preloadFn = async (event) => {
		await this.handleSubmit(event);
	};

	render() {
		return (
			<div className='py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-10 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'>Edición de QR</h4>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div>
										<BusinessSelector
											handler={this.handleChangeBus}
											selectedB={sessionStorage.getItem('idBusiness')}
										/>
									</div>
								)}
							</div>
							<div className='card-body'>
								<form>
									<div className='row'>
										<div className='col-sm-12 col-md-6 col-lg-4 p-2'>
											<div className='row p-4'>
												<div className='col-12'>
													<div className='text-center'>
														<div>
															<label>Visualiza tu cupón:</label>
														</div>
														<div className='imgg'>
															<img
																id='qrPanel'
																src='https://softitlan-storage.s3.us-east-2.amazonaws.com/images/cuponealo-cargandoqrgif.gif'
																className='img-thumbnail'
																width='75%'
																alt='Vista previa'
															/>
														</div>
													</div>
													<br />
												</div>
												<div
													id='previewButton'
													className='btn btn-primary btn-md btn-block'
													onClick={() => this.getPicture(this.state.form)}
												>
													Previsualizar
												</div>
											</div>
										</div>
										<div className='col-sm-12 col-md-6 col-lg-4 p-4'>
											<div className='form-group row'>
												<label> Tipo de Esquinas </label>
												<select
													className='form-control'
													aria-label='.form-select-lg example'
													name='corner'
													id='corner'
													value={this.state.option}
													required
													onChange={this.handleChangeInt}
												>
													<option value=''>Seleccione...</option>
													{this.state.types.corner.map((c, i) => (
														<option key={i} value={i + 1}>
															{c.description}
														</option>
													))}
												</select>
											</div>
											<div className='form-group row'>
												<label> Tipo de Ojos </label>
												<select
													className='form-control'
													aria-label='.form-select-lg example'
													name='eye'
													id='eye'
													value={this.state.option}
													required
													onChange={this.handleChangeInt}
												>
													<option value=''>Seleccione...</option>
													{this.state.types.eye.map((c, i) => (
														<option key={i} value={i + 1}>
															{c.description}
														</option>
													))}
												</select>
											</div>
											<div className='form-group row'>
												<label> Tipo de patrón </label>
												<select
													className='form-control'
													aria-label='.form-select-lg example'
													name='pattern'
													id='pattern'
													value={this.state.option}
													required
													onChange={this.handleChange}
												>
													<option value=''>Seleccione...</option>
													{this.state.types.pattern.map((c, i) => (
														<option key={i} value={c.description}>
															{c.description}
														</option>
													))}
												</select>
											</div>
											<div className='form-group row'>
												<label> Tipo de degradado</label>
												<select
													className='form-control'
													aria-label='.form-select-lg example'
													name='gradient'
													id='gradient'
													value={this.state.option}
													required
													onChange={this.handleChange}
												>
													<option value=''>Seleccione...</option>
													{this.state.types.gradient.map((c, i) => (
														<option key={i} value={c.description}>
															{c.description}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className='col-sm-12 col-md-12 col-lg-4 p-4'>
											<div className='container-fluid'>
												<br />
												<div className='row'>
													<div className='col-6'>
														<label>Con borde:</label>
														<div className='input-group-prepend'>
															<div className='input-group-text'>
																<input
																	type='radio'
																	aria-label='Radio button for following text input'
																	name='border'
																	id='borderSi'
																	onChange={this.handleChange}
																	value='si'
																/>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<label>Sin borde:</label>
														<div className='input-group-prepend '>
															<div className='input-group-text'>
																<input
																	type='radio'
																	aria-label='Radio button for following text input'
																	name='border'
																	id='borderNo'
																	onChange={this.handleChange}
																	value='no'
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* Aqui va el controlador de colores RGB */}
											<div className='container-fluid '>
												<br />
												{this.state.action !== '' ? (
													<div className='row'>
														<div className='col-4'>
															<label>Centro</label>
															<ColorSelect
																onColorChange={this.handleChangeCc}
																colorIn={this.state.form.centerColor}
															/>
														</div>
														<div className='col-4'>
															<label>Fondo</label>
															<ColorSelect
																onColorChange={this.handleChangeCf}
																colorIn={this.state.form.backgroundColor}
															/>
														</div>
														<div className='col-4'>
															<label>Esquinas</label>
															<ColorSelect
																onColorChange={this.handleChangeCe}
																colorIn={this.state.form.cornerColor}
															/>
														</div>
													</div>
												) : (
													<></>
												)}
											</div>
											{/* Colores RGB */}
										</div>
										<div className='col-12 p-4'>
											<div className='col-12'>
												<div className='form-group row'>
													<Preload type='click' callToService={this.preloadFn}>
														{this.state.action === 'insert' ? (
															<input
																className='btn btn-success btn-md btn-block'
																style={{ width: '100%' }}
																type='submit'
																value='Registrar cupón'
															/>
														) : (
															<input
																className='btn btn-success btn-md btn-block'
																style={{ width: '100%' }}
																type='submit'
																value='Actualizar QR'
															/>
														)}
													</Preload>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(QrFormComponent);
