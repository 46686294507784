import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ClientGridController } from '../../ClientGrid/controller/ClientGridController';
import { ClientFormController } from '../controller/ClientFormController';
import { withRouter } from 'react-router-dom';
import { Home, Search, ArrowLeft, ArrowRight } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { Verify } from '../../utils/Verify';

class ClientSelectComponent extends React.Component {
	constructor(props) {
		super(props);
		this.clientFormController = new ClientFormController();
		this.clientGridController = new ClientGridController();
		this.verify = new Verify();
		this.state = {
			tableData: {
				page: 0,
				limit: 8,
				text: '',
				column: 'name'
			},
			page: 0,
			value: '',
			view: 0,
			data: [],
			forme: {
				id: 0,
				email: '',
				status: 1
			},
			personVO: {
				name: '',
				surname: '',
				phone: 0,
				birthdate: ''
			},
			nombreCliente: '',
			vpersonList: [],
			allclientes: [],
			charging: 'no',
			filterClients: {
				data: [],
				total: 0
			}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload();
		}
		await this.loadAllClientes();
	}

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.props.history.push('/cuponGrid');
	};

	onChange = (d) => {
		const formeArray = {
			id: d.id,
			email: d.email,
			status: d.status
		};
		const personVOArray = {
			name: d.personVO.name,
			surname: d.personVO.surname,
			phone: d.personVO.phone,
			birthdate: d.personVO.birthdate
		};
		this.setState({
			forme: formeArray,
			personVO: personVOArray
		});
	};

	getNext = async (event) => {
		event.preventDefault();

		this.setState(
			{
				page: this.state.page + 1,
				tableData: {
					...this.state.tableData,
					page: this.state.tableData.page + 1,
					text: document.getElementById('busqueda In').value
				},
				filterClients: {
					...this.state.filterClients,
					data: []
				}
			},
			() => {
				this.loadAllClientes();
			}
		);
	};

	getPrev = async (event) => {
		event.preventDefault();

		if (this.state.tableData.page > 0) {
			this.setState(
				{
					page: this.state.page - 1,
					tableData: {
						...this.state.tableData,
						page: this.state.tableData.page - 1,
						text: document.getElementById('busqueda In').value
					},
					filterClients: {
						...this.state.filterClients,
						data: []
					}
				},
				() => {
					this.loadAllClientes();
				}
			);
		} else {
			Utils.swalSuccess('No se puede obtener datos previos.');
		}
	};

	renderBodyTable() {
		return this.state.filterClients.data.map((d, i) => (
			<div
				className={
					i % 2 === 0
						? 'row py-2 bg-dark text-white'
						: 'row py-2 bg-secondary text-white'
				}
				key={i}
			>
				<div className='col-12'>
					<input
						type='radio'
						value={d.personVO.name}
						name='select'
						onChange={() => this.onChange(d)}
					/>
					{'  ' +
						d.personVO.name +
						' ' +
						d.personVO.surname +
						' - ' +
						d.email +
						' - ' +
						d.personVO.phone}
				</div>
			</div>
		));
	}

	asignar = async (event) => {
		event.preventDefault();
		await this.clientFormController.add(this.state.forme, this.state.personVO);
		this.props.history.push(
			'/cuponData?code=' + sessionStorage.getItem('qrAddres')
		);
	};

	filtrar = (event) => {
		this.setState(
			{
				page: 0,
				tableData: {
					...this.state.tableData,
					text: document.getElementById('busqueda In').value,
					page: 0
				},
				filterClients: {
					...this.state.filterClients,
					data: []
				}
			},
			() => {
				this.loadAllClientes(true);
			}
		);
	};

	loadAllClientes = async (change) => {
		this.setState({ charging: 'yes' }, async () => {
			const body = await this.clientGridController.findAllFilter(
				this.state.tableData
			);
			let pagAux = this.state.page;

			if (change) {
				pagAux = 0;
			}

			this.setState({
				data: body,
				filterClients: body,
				page: pagAux,
				charging: 'no'
			});
		});
	};

	render() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'> Selección del Cliente</h4>
							</div>
							<div className='card-body'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='input-group'>
											<input
												type='text'
												className='form-control'
												id='busqueda In'
												placeholder='Ingrese el nombre del cliente'
												aria-describedby='busqueda'
												onKeyUp={this.filtrar}
												required
											/>
											<div className='input-group-prepend mr-5'>
												<span className='input-group-text' id='busqueda'>
													<Search />
												</span>
											</div>
										</div>
									</div>

									<div className='row justify-content-center'>
										<div className='container-fluid py-1'>
											{this.renderBodyTable()}
											<hr />
											<button
												onClick={
													this.state.personVO.name === '' ? null : this.asignar
												}
												className={
													this.state.personVO.name === ''
														? 'btn btn-success btn-md btn-block disabled'
														: 'btn btn-success btn-md btn-block'
												}
											>
												Asignar y Autorizar
											</button>
										</div>
										<div className='container-fluid py-1'>
											<div className='row'>
												<div className='col-9 align-content-end'>
													Página: {this.state.page + 1} de{' '}
													{Math.ceil(this.state.data.total / 8)}
												</div>
												<div className='col-1'>
													{this.state.page === 0 ? (
														<></>
													) : (
														<ArrowLeft onClick={this.getPrev} />
													)}
												</div>
												<div className='col-1'>
													{this.state.data.total > (this.state.page + 1) * 8 ? (
														<ArrowRight onClick={this.getNext} />
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ClientSelectComponent);
