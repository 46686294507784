import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ClientGridController } from '../../ClientGrid/controller/ClientGridController';
import { ClientFormController } from '../controller/ClientFormController';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { Verify } from '../../utils/Verify';

class ClientFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.clientGridController = new ClientGridController();
		this.clientFormController = new ClientFormController();
		this.verify = new Verify();
		this.state = {
			view: 0,
			data: [],
			forme: {
				id: 0,
				email: '',
				status: 0,
				personVO: {
					name: '',
					surname: '',
					phone: 0,
					birthdate: ''
				}
			},
			client: {}
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		this.actualForm();
	}

	handleChange = (e) => {
		this.setState({
			forme: {
				...this.state.forme,
				[e.target.name]: e.target.value
			}
		});
	};

	handleChangeD = (e) => {
		this.setState({
			forme: {
				...this.state.forme,
				personVO: {
					...this.state.forme.personVO,
					[e.target.name]: e.target.value
				}
			}
		});
	};

	back = (_) => {
		if (this.state.view === 0) {
			window.location.reload(true);
		} else {
			window.history.back();
		}
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	actualForm() {
		if (this.props.form) {
			this.props.form.personVO.birthdate =
				this.props.form.personVO.birthdate.substring(6, 10) +
				'-' +
				this.props.form.personVO.birthdate.substring(3, 5) +
				'-' +
				this.props.form.personVO.birthdate.substring(0, 2);
			this.setState({ view: 0 });
			this.setState({ forme: this.props.form });
		} else {
			this.setState({ view: 1 });
		}
	}

	maxDate() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1; // January is 0!
		const yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		today = `${yyyy}-${mm}-${dd}`;
		return today;
	}

	/* Editar usuario */
	edit = async (event) => {
		event.preventDefault();
		if (this.state.view === 0) {
			const data = await this.clientGridController.edit(this.state.forme);
			if (data.indexOf('success') !== -1) {
				Utils.swalSuccess('¡Se editó al cliente correctamente!');
				setTimeout(window.location.reload(true), 3000);
			} else if (data.includes('telefono')) {
				Utils.swalError('El teléfono que intenta ingresar ya existe...');
			} else if (data.includes('usuario') || data.includes('email')) {
				Utils.swalError('El correo que intenta ingresar ya existe...');
			} else {
				Utils.swalError('Ocurrió un error al editar el usuario.');
				window.location.reload(true);
			}
		}
	};

	renderFormEdit() {
		return (
			<table className='table table-dark table-striped'>
				<tbody>
					<tr>
						<td>
							<label>Nombre:</label>
							<input
								className='form-control'
								type='text'
								name='name'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength={45}
								onChange={this.handleChangeD}
								value={this.state.forme.personVO.name}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Apellidos:</label>
							<input
								className='form-control'
								type='text'
								name='surname'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength={45}
								onChange={this.handleChangeD}
								value={this.state.forme.personVO.surname}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Fecha de Nacimiento:</label>
							<input
								className='form-control'
								type='date'
								name='birthdate'
								onChange={this.handleChangeD}
								min='1900-01-01'
								max={this.maxDate()}
								data-date-format='yyyy-MM-dd'
								value={this.state.forme.personVO.birthdate}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Correo Electrónico: </label>
							<input
								className='form-control'
								type='email'
								name='email'
								pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$'
								title='Letras a-z, números 0-9 y simbolos ._%+- "example@host.com"'
								placeholder='example@host.com'
								maxLength={45}
								onChange={this.handleChange}
								value={this.state.forme.email}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Teléfono:</label>
							<input
								className='form-control'
								type='tel'
								name='phone'
								placeholder='xxxxxxxxxx'
								maxLength='10'
								min='1000000000'
								pattern='[0-9]{10}'
								onChange={this.handleChangeD}
								value={this.state.forme.personVO.phone}
								title='Proporcione solo 10 dígitos en números del 0 al 9'
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<input
								className='btn btn-primary btn-block'
								type='submit'
								value='Cambiar Datos'
							/>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className='container h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								{this.state.view === 0 ? (
									<h4 className='text-center'> Edición de Cliente</h4>
								) : (
									<h4 className='text-center'> Alta de Cliente </h4>
								)}
							</div>

							<div className='card-body'>
								<div className='container'>
									<div className='table-responsive'>
										<form onSubmit={this.edit}>{this.renderFormEdit()}</form>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ClientFormComponent);
