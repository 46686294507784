import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class ClientFormController {
	async insert(forme, person, client) {
		const cupon = sessionStorage.getItem('qrAddres');
		const res = await Client.POST({
			url: Urls.cuponsApi.auth + '?codeText=' + cupon,
			alert: 'show',
			data: {
				id: forme.id,
				idPerson: client.personVO.id,
				personVO: client.personVO,
				email: forme.email,
				status: forme.status,
				createdAt: new Date(),
				modifiedAt: new Date(),
				createdBy: 1,
				modifiedBy: 1
			}
		});
		return res;
	}

	async add(forme, person) {
		const cupon = sessionStorage.getItem('qrAddres');
		const idBusiness = sessionStorage.getItem('idBusiness');
		const res = await Client.POST({
			url: Urls.cuponsApi.auth,
			alert: 'show',
			params: {
				codeText: cupon,
				idBusiness: idBusiness
			},
			data: {
				id: 0,
				idPerson: 0,
				personVO: person,
				email: forme.email,
				status: 1,
				createdAt: new Date(),
				modifiedAt: new Date(),
				createdBy: 1,
				modifiedBy: 1
			}
		});
		return res;
	}

	async verify(forme, person) {
		const resPhone = await Client.GET({
			url: Urls.clientsApi.verifyPhone,
			alert: 'show',
			params: {
				phone: person.phone.toString()
			}
		});
		const resEmail = await Client.GET({
			url: Urls.clientsApi.verifyEmail,
			params: {
				email: forme.email.toString()
			}
		});
		return {
			phoneVerif: resPhone,
			emailVerif: resEmail
		};
	}
}
