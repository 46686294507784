import React from 'react';
import { Utils } from '../../resources/Utils';
import { BirthdayController } from '../controller/BirthdayController';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import './BirthdayStyles.css';

class BirthdayComponent extends React.Component {
	constructor(props) {
		super(props);
		this.BirthdayC = new BirthdayController();
		this.verify = new Verify();
		this.state = {
			cadena: '',
			urls: [],
			total: 0,
			start: 0,
			limit: 8,
			data: {
				dia: {
					list: []
				},
				mes: {
					list: []
				}
			}
		};
	}

	async componentDidMount() {
		this.verify1();
		this.init();
	}

	async init() {
		const res = await this.BirthdayC.init();
		if (res) {
			this.setState({ data: res });
		}
	}

	verify1() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
	}

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	formatDate2 = (date) => {
		const fecha =
			date.substring(8, 10) +
			'-' +
			date.substring(5, 7) +
			'-' +
			date.substring(0, 4);
		return fecha;
	};

	copyEmail = (op) => {
		let cadena = '';
		let data;
		if (op === 1) {
			data = this.state.data.dia.list;
		} else {
			data = this.state.data.mes.list;
		}
		data.map((d) => (cadena = cadena + d.email + ','));
		const aux = document.createElement('input');
		aux.setAttribute('value', cadena);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
		Utils.swalSuccess('COPIADO!');
	};

	renderBody() {
		return this.state.data.dia.list.map((d) => (
			<tr key={d.id}>
				<td>{d.personVO.name}</td>
				<td>{d.personVO.surname}</td>
				<td>{d.personVO.phone}</td>
				<td>{this.formatDate2(d.personVO.birthdate)}</td>
				<td>{d.email}</td>
			</tr>
		));
	}

	renderBody2() {
		return this.state.data.mes.list.map((d) => (
			<tr key={d.id}>
				<td>{d.personVO.name}</td>
				<td>{d.personVO.surname}</td>
				<td>{d.personVO.phone}</td>
				<td>{this.formatDate2(d.personVO.birthdate)}</td>
				<td>{d.email}</td>
			</tr>
		));
	}

	render() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'>Cumpleañeros</h4>
							</div>
							<div className='card-body'>
								<div className='container-fluid'>
									<div className='row justify-content-center'>
										<div className='table-responsive py-3'>
											<h5>
												<label>Cumpleañeros del Día</label>
											</h5>
											<table className='table table-dark table-striped t1'>
												<thead className='thead-dark'>
													<tr>
														<th scope='col'>Nombre</th>
														<th scope='col'>Apellidos</th>
														<th scope='col'>Teléfono</th>
														<th scope='col'>Fecha de Nacimiento</th>
														<th scope='col'>Correo Electrónico</th>
													</tr>
												</thead>
												<tbody>{this.renderBody()}</tbody>
											</table>
										</div>
										<div className='container-fluid'>
											{!this.state.data.dia.list[0] ? (
												<p />
											) : (
												<input
													className='btn btn-success btn-md btn-block'
													type='submit'
													value='Copiar correos'
													onClick={() => this.copyEmail(1)}
												/>
											)}
										</div>
										<div className='table-responsive py-3'>
											<h5>
												<label>Cumpleañeros del Mes</label>
											</h5>
											<table className='table table-dark table-striped t2'>
												<thead className='thead-dark'>
													<tr>
														<th scope='col'>Nombre</th>
														<th scope='col'>Apellidos</th>
														<th scope='col'>Teléfono</th>
														<th scope='col'>Fecha de Nacimiento</th>
														<th scope='col'>Correo Electrónico</th>
													</tr>
												</thead>
												<tbody>{this.renderBody2()}</tbody>
											</table>
										</div>
										<div className='container-fluid'>
											{!this.state.data.mes.list[0] ? (
												<p />
											) : (
												<input
													className='btn btn-success btn-md btn-block'
													type='submit'
													value='Copiar correos'
													onClick={() => this.copyEmail(2)}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BirthdayComponent);
