import { Urls } from '../../resources/Urls';
import { Utils } from '../../resources/Utils';
import { Client } from '../../resources/Client';

export class CuponDataController {
	response = {};
	qrAddress = '';

	formatDate = (date) => {
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		if (month < 10) {
			if (day < 10) return `0${day}-0${month}-${year}`;
			else return `${day}-0${month}-${year}`;
		} else {
			if (day < 10) return `0${day}-${month}-${year}`;
			else return `${day}-${month}-${year}`;
		}
	};

	getUrlParameter = (sParam) => {
		const sPageURL = window.location.search.substring(1);
		const sURLVariables = sPageURL.split('&');
		let sParameterName;
		let i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined
					? true
					: decodeURIComponent(sParameterName[1]);
			}
		}
	};

	getInfoCupon = async (idCupon) => {
		const res = await Client.GET({
			url: Urls.cuponsApi.scanCoupon + '?codeText=' + idCupon,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		}).catch((e) => {
			Utils.swalError('ayno');
		});

		this.response = await res.data;
	};

	init = async () => {
		this.qrAddress = this.getUrlParameter('code');
		if (this.qrAdrres !== null && this.qrAddress) {
			await this.getInfoCupon(this.qrAddress);
		}
		sessionStorage.setItem('qrAddres', this.qrAddress);
		return this.response;
	};

	async autorizar() {
		if (sessionStorage.getItem('token')) {
			return await Client.GET({
				url: Urls.cuponsApi.autorizar,
				params: {
					codeText: this.qrAddress
				}
			});
		} else {
			alert('Por favor inicie sesión');
			return false;
			// window.location = Urls.navApi.login;
		}
	}

	async autorizar2() {}
}
