import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class BusinessFormController {
	async insert(datos) {
		const datosEnv = {
			name: datos.bussinesVO.name,
			slogan: datos.bussinesVO.slogan,
			description: datos.bussinesVO.description,
			phone: datos.bussinesVO.phone,
			contacEmail: datos.bussinesVO.contacEmail,
			googlemaps: datos.bussinesVO.googlemaps,
			instagramLink: datos.bussinesVO.instagramLink,
			facebookLink: datos.bussinesVO.facebookLink,
			twitterLink: datos.bussinesVO.twitterLink,
			mainSociamedia: datos.bussinesVO.mainSociamedia,
			'img-banner': datos.bussinesVO.bannerLink,
			'img-profile': datos.bussinesVO.profileLink,
			'cat-id': datos.catalogCatVOf.id,
			logoLink: datos.bussinesVO.logoLink,
			latitude: '' + datos.coordsMap[0].position.lat,
			longitude: '' + datos.coordsMap[0].position.lng
		};

		const res = await Client.POST({
			url: Urls.businessApi.insert,
			alert: 'show',
			data: datosEnv
		});

		return res;
	}

	async sendI(imgQR) {
		const resp = await Client.POST({
			url: Urls.businessApi.saveImage,
			data: imgQR
		});
		return resp;
	}

	async findCatalog() {
		const res = await Client.GET({
			url: Urls.businessApi.findCatalog,
			alert: 'show',
			params: {
				page: 0,
				limit: 100
			}
		});
		return res;
	}

	async findCoords(idBusiness) {
		const res = await Client.GET({
			url: Urls.coordsApi.findByBussinesId,
			params: {
				id: idBusiness
			}
		});
		return res;
	}

	async findPicture(id) {
		const res = await Client.GET({
			url: Urls.businessApi.findPicture,
			params: {
				idB: id
			}
		});
		return res;
	}

	async update(datos) {
		let auxC = [];
		const coords = [];
		let idC = -1;

		datos.coordsMap.forEach((c) => {
			Object.prototype.hasOwnProperty.call(c, 'idBusiness')
				? (idC = c.id)
				: (idC = -1);

			auxC = [
				{
					id: idC,
					latitude: '' + c.position.lat,
					longitude: '' + c.position.lng,
					idBusiness: datos.bussinesVO.id
				}
			];

			coords.push.apply(coords, auxC);
			auxC = [];
		});

		await Client.POST({
			url: Urls.businessApi.update,
			alert: 'show',
			data: {
				idB: datos.bussinesVO.id,
				name: datos.bussinesVO.name,
				slogan: datos.bussinesVO.slogan,
				description: datos.bussinesVO.description,
				phone: datos.bussinesVO.phone,
				contacEmail: datos.bussinesVO.contacEmail,
				instagramLink: datos.bussinesVO.instagramLink,
				facebookLink: datos.bussinesVO.facebookLink,
				twitterLink: datos.bussinesVO.twitterLink,
				googlemaps: datos.bussinesVO.googlemaps,
				mainSociamedia: datos.bussinesVO.mainSociamedia,
				urlBanner: datos.catalogImgVOf.description,
				urlPic: datos.pictureVO.urlImg,
				logoLink: datos.bussinesVO.logoLink,
				coordinates: coords,
				borrados: datos.borrados,
				category: datos.catalogCatVOf.id
			}
		});
	}

	async updateImage(datos) {
		const response = await fetch(Urls.filesApi.edit, {
			method: 'POST',
			body: datos
		}).then((response) => response.json());

		return response;
	}

	async sendImage(datos) {
		const response = await fetch(Urls.filesApi.upload, {
			method: 'POST',
			body: datos
		}).then((response) => response.json());

		return response;
	}

	async insertar(datos) {
		const resp = await Client.POST({
			url: Urls.categoriesApi.insert,
			data: {
				description: datos.otherCat
			},
			alert: 'show'
		});

		return resp;
	}

	async getLast() {
		const resp = await Client.GET({
			url: Urls.businessApi.getLast
		});

		return resp.data + 1;
	}
}
