import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class BusinessController {
	async findCoords(idBusiness) {
		const res = await Client.GET({
			url: Urls.coordsApi.findByBussinesId,
			params: {
				id: idBusiness
			}
		});
		return res;
	}

	async getInfoCupon(page) {
		const info = JSON.parse(sessionStorage.getItem('info'));
		let idBusiness;
		if (info.id !== undefined || info.id !== null) {
			idBusiness = info.id;
		} else {
			idBusiness = 1;
		}

		const res = await Client.GET({
			url: Urls.cuponsApi.findAllAvailable,
			alert: 'show',
			params: {
				page: page,
				limit: 6,
				id: idBusiness
			}
		});
		return res;
	}

	async getPreview(datos) {
		return await fetch(Urls.cuponsApi.generateQRToBusiness, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(datos)
		}).then((response) => response.blob());
	}
}
