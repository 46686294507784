import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class CuponGridController {
	async init(tableData) {
		let idBusiness;
		if (
			sessionStorage.getItem('role') === 'sudosu' &&
			(sessionStorage.getItem('idBusiness') === null ||
				sessionStorage.getItem('idBusiness') === undefined)
		) {
			idBusiness = 1;
		} else {
			idBusiness = sessionStorage.getItem('idBusiness');
		}
		const res = await Client.GET({
			url: Urls.cuponsApi.findAll,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit,
				id: idBusiness
			}
		});
		return res;
	}

	async findAllActive(tableData) {
		const res = await Client.GET({
			url: Urls.clientsApi.findAllActive,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit
			}
		});
		return res;
	}

	async delete(id) {
		await Client.POST({
			url: Urls.cuponsApi.update,
			alert: 'show',
			params: {
				id: id
			},
			data: {
				status: '0'
			}
		});
	}

	async active(id) {
		await Client.POST({
			url: Urls.cuponsApi.update,
			alert: 'show',
			params: {
				id: id
			},
			data: {
				status: '1'
			}
		});
	}

	async edit(forme) {
		await Client.POST({
			url: Urls.cuponsApi.update,
			alert: 'show',
			params: {
				id: forme.id
			},
			data: {
				description: forme.description,
				lifes: forme.lifes,
				expirationDate: forme.expirationDate
			}
		});
	}

	async createSon(id) {
		const response = await Client.POST({
			url: Urls.cuponsApi.createSon,
			alert: 'show',
			params: {
				id: id
			}
		});
		sessionStorage.setItem('qrAddres', response.qr_address);
	}
}
