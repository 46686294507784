import React from 'react';
import { ForgottenController } from '../controller/ForgottenController';
import { withRouter } from 'react-router-dom';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { Urls } from '../../resources/Urls';
import { Utils } from '../../resources/Utils';

class ForgottenComponent extends React.Component {
	constructor() {
		super();
		this.ForgottenController = new ForgottenController();
		this.state = {
			email: ''
		};
	}

	back = (event) => {
		window.history.back();
	};

	menu = (event) => {
		window.location = Urls.navApi.mainMenu;
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const res = await this.ForgottenController.recoverPassword(
			this.state.email
		);
		if (res === 'true') {
			Utils.swl('¡Se ha enviado una nueva contraseña a su correo!');
			setTimeout(() => {
				this.props.history.push('/login');
			}, 1000);
		} else {
			Utils.swalError('No se pudo enviar el correo de recuperación');
		}
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	render() {
		return (
			<div className='container h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-md-6 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header bg-dark text-white'>
								<h3 className='text-center'>Recuperar Contraseña</h3>
								<div id='mess'></div>
							</div>
							<div className='card-body'>
								<form onSubmit={this.handleSubmit}>
									<div className='form-group'>
										<label htmlFor='user'>Correo electrónico:</label>
										<input
											className='form-control'
											type='email'
											placeholder='Correo electrónico'
											value={this.state.email}
											onChange={this.handleChange}
											required
											name='email'
											id='email'
										/>
									</div>
									<input
										type='submit'
										value='Enviar'
										className='btn btn-success btn-md btn-block'
									/>
								</form>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ForgottenComponent);
