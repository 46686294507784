import { Client } from '../../resources/Client';
import { Urls } from '../../resources/Urls';
import { Utils } from '../../resources/Utils';

export class ForgottenController {
	async getAllClient() {
		const data = await Client.GET({
			url: Urls.clientsApi.findAllActive,

			params: {
				start: 0,
				limit: 8
			}
		});
		return data;
	}

	async recoverPassword(email) {
		const url = Urls.sessionApi.recoverPassword;

		const res = await Client.POST({
			url: url + '?email=' + email
		});

		let jsonResponse;
		if (res) {
			jsonResponse = res;
		} else {
			Utils.swalError('No se pudo recuperar la contraseña.');
		}

		return jsonResponse;
	}

	async recoverPassword2(email) {
		const res = await this.recoverPassword(email);

		if (res) {
			Utils.swalSuccess('La contraseña se reestableció con éxito.');
		} else {
			Utils.swalError(
				'No se pudo restablecer la contraseña. Verifique que el correo esté bien escrito o que sea válido.'
			);
		}
	}
}
