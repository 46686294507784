import React from 'react';
import { ScannerController } from '../controller/ScannerController';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import { Utils } from '../../resources/Utils';

class ScannerComponent extends React.Component {
	constructor(props) {
		super(props);
		this.scannerController = new ScannerController();
		this.verify = new Verify();
		this.state = {
			delay: 1500,
			result: 'No result'
		};
		this.timeoutID = undefined;
	}

	back = (_) => {
		window.history.back();
		window.location.reload();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
		window.location.reload();
	};

	handleScan = (e) => {
		if (
			e &&
			e.target.value !== '' &&
			e.target.value !== 'No QR code found' &&
			e.target.value !== null
		) {
			this.props.history.push('/cuponData?code=' + e.target.value.trim());
		}
	};

	verCam(e) {
		if (!e || e.target.value === 'false') {
			Utils.swalError('¡No cuenta con una camara!');
		}
	}

	componentDidMount() {
		if (!this.verify.securityVerify('employee')) {
			this.props.history.push('/');
		}

		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}

		if (sessionStorage.getItem('visited') === '0') {
			sessionStorage.setItem('visited', '1');

			window.location.reload(true);
		}

		this.timeoutID = setTimeout(() => {
			if (
				document.getElementsByClassName('scan-region-highlight').length === 0
			) {
				Utils.swalError(
					'Hubo un error al inicializar la cámara. Favor de validar que haya otorgado permisos para usar la cámara y luego recargue la página.'
				);
			}
		}, 7000);
		window.addEventListener('popstate', this.handlePopstate);
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutID);
	}

	handlePopstate = () => {
		window.location.reload();
	};

	render() {
		return (
			<div className='container-fluid h-100 py-5'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'>Escaneo de QR</h4>
							</div>
							<div className='card-body'>
								<div className='mx-auto'>
									<div className='container-fluid'>
										<div className='row justify-content-center'>
											<div className='text-center' id='video-container'>
												<video id='qr-video' style={{ width: '80%' }}></video>
											</div>

											<div style={{ display: 'none' }}>
												<form>
													<input
														type='text'
														id='has-cam'
														onClick={this.verCam}
													/>
													<input type='text' id='has-flash' />
													<input
														type='text'
														id='code'
														onClick={this.handleScan}
													/>
												</form>
											</div>
										</div>

										<div className='text-center'>
											<button id='flash-toggle'>
												📸 Flash: <span id='flash-state'>Apagado</span>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos id='btnbackscanner' onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home id='btnhomescanner' onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ScannerComponent);
