import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { TikTok } from 'react-tiktok';
import { Urls } from '../../resources/Urls';
import 'react-multi-carousel/lib/styles.css';
import './StyleSocial.css';
import '../../resources/css/General.css';
import Warning from '@material-ui/icons/WarningOutlined';
import { Utils } from '../../resources/Utils';

class SocialNetworkComponent extends React.Component {
	checkIsValidSource = (url, type) => {
		return !(url !== '' && url.split('')[0] === 'https:');
	};

	renderNetworkNone = () => {
		return (
			<div className='align-content-center align-items-center'>
				<h4>Sin red social</h4>
				<Warning className='text-center' />
			</div>
		);
	};

	selectMain = () => {
		switch (this.props.props.option) {
			case 'Twitter': {
				const user = this.props.props.user.twitter;
				if (this.checkIsValidSource(user, 'Twitter')) {
					try {
						return (
							<>
								<TwitterTimelineEmbed sourceType='profile' screenName={user} />
							</>
						);
					} catch (e) {
						return this.renderNetworkNone();
					}
				} else this.renderNetworkNone();
				break;
			}
			case 'Facebook': {
				if (
					this.checkIsValidSource(this.props.props.user.facebook, 'Facebook')
				) {
					try {
						return (
							<>
								<iframe
									src={
										Urls.socialApi.facebook +
										this.props.props.user.facebook +
										'&tabs=timeline&adapt_container_width=true'
									}
									style={{
										minHeight: 520,
										background: 'transparent',
										border: 'none'
									}}
									width='95%'
									title='facebookPag'
									allowFullScreen='true'
									onError={(para) => {
										Utils.swl({
											title: 'No se pudo obtener el perfil del negocio '
										});
									}}
									allow='autoplay; clipboard-write;'
								/>
							</>
						);
					} catch (e) {
						return this.renderNetworkNone();
					}
				} else this.renderNetworkNone();
				break;
			}
			case 'Instagram': {
				const userLink =
					'https://www.tiktok.com/@' + this.props.props.user.tikTok;

				if (this.checkIsValidSource(userLink, 'Instagram')) {
					try {
						return (
							<>
								<div className='tiktok'>
									<TikTok url={userLink} />
								</div>
							</>
						);
					} catch (e) {
						return this.renderNetworkNone();
					}
				} else this.renderNetworkNone();
				break;
			}
			default: {
				return this.renderNetworkNone();
			}
		}
	};

	render() {
		return <div className='social'>{this.selectMain()}</div>;
	}
}
export default SocialNetworkComponent;
