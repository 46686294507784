import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ClientGridController } from '../../ClientGrid/controller/ClientGridController';
import { ClientFormController } from '../controller/ClientFormController';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { Verify } from '../../utils/Verify';
import BusinessSelector from '../../resources/BusinessSelector';

class ClientAddComponent extends React.Component {
	constructor(props) {
		super(props);
		this.clientFormController = new ClientFormController();
		this.clientGridController = new ClientGridController();
		this.verify = new Verify();
		this.state = {
			view: 0,
			data: [],
			forme: {
				id: 0,
				email: '',
				status: 1
			},
			personVO: {
				name: '',
				surname: '',
				phone: null,
				birthdate: ''
			},
			client: {}
		};
	}

	componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
	}

	handleChange = (e) => {
		this.setState({
			forme: {
				...this.state.forme,
				[e.target.name]: e.target.value
			}
		});
	};

	handleChangeD = (e) => {
		this.setState({
			personVO: {
				...this.state.personVO,
				[e.target.name]:
					e.target.name === 'phone'
						? e.target.value.replace(/\D/g, '')
						: e.target.value
			}
		});
	};

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	edit = async (event) => {
		event.preventDefault();
		const res = await this.clientFormController.verify(
			this.state.forme,
			this.state.personVO
		);
		if (res.emailVerif.success === true && res.phoneVerif.success === true) {
			await this.clientFormController.add(
				this.state.forme,
				this.state.personVO
			);
			this.props.history.push(
				'/cuponData?code=' + sessionStorage.getItem('qrAddres')
			);
		} else {
			if (res.emailVerif.success !== true) {
				Utils.swl({
					icon: 'info',
					text: 'El correo ya está en uso.',
					title: 'Verifique nuevamente la información.'
				});
			}
			if (res.phoneVerif.success !== true) {
				Utils.swl({
					icon: 'info',
					text: 'El teléfono ya está en uso.',
					title: 'Verifique nuevamente la información.'
				});
			}
		}
	};

	parseDate = (date) => {
		const cadena = this.Utils.formatDate(date);
		return cadena;
	};

	maxDate() {
		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		const yyyy = today.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		today = yyyy + '-' + mm + '-' + dd;
		return today;
	}

	actionPulse = (event) => {
		event.preventDefault();
		this.props.history.push('/clientSelect');
	};

	renderFormEdit() {
		return (
			<table className='table table-dark table-striped'>
				<tbody>
					<tr>
						<td>
							<label>Nombre:</label>
							<input
								className='form-control'
								type='text'
								name='name'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength='50'
								onChange={this.handleChangeD}
								value={this.state.personVO.name}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Apellidos:</label>
							<input
								className='form-control'
								type='text'
								pattern='^[A-ZÁÉÍÓÚ][a-zñáéíóú]+(?: [A-ZÁÉÍÓÚ][a-zñáéíóú]+)?$'
								maxLength='50'
								name='surname'
								onChange={this.handleChangeD}
								value={this.state.personVO.surname}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Fecha de Nacimiento:</label>
							<input
								className='form-control'
								type='date'
								name='birthdate'
								id='birthdate'
								min='1900-01-01'
								onChange={this.handleChangeD}
								max={this.maxDate()}
								data-date-format='yyyy-MM-dd'
								value={this.state.personVO.birthdate}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Correo Electrónico: </label>
							<input
								className='form-control'
								type='email'
								name='email'
								pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$'
								title='Letras a-z, números 0-9 y simbolos ._%+- "example@host.com"'
								placeholder='example@host.com'
								onChange={this.handleChange}
								value={this.state.forme.email}
								required
							/>
						</td>
					</tr>
					<tr>
						<td>
							<label>Teléfono:</label>
							<input
								className='form-control'
								type='tel'
								name='phone'
								placeholder='xxxxxxxxxx'
								maxLength='10'
								min='1000000000'
								pattern='[0-9]{10}'
								onChange={this.handleChangeD}
								value={this.state.personVO.phone}
								required
							/>
						</td>
					</tr>
					<tr>
						<td colSpan='2'>
							<input
								className='btn btn-success btn-block'
								type='submit'
								value='Registrar y Autorizar'
							/>
							<br />
							<button
								type='button'
								className='btn btn-warning btn-md btn-block'
								onClick={this.actionPulse}
							>
								Seleccionar Cliente
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	showData() {
		return (
			<div className='col-sm-6'>
				<select className='form-control' id='asesor'>
					<>
						<option key={0}>Seleccione...</option>
						{this.state.data.map((d) => (
							<option key={d.id}>
								{d.personVO.name + ' ' + d.personVO.surname}
							</option>
						))}
					</>
				</select>
			</div>
		);
	}

	render() {
		return (
			<div className='container py-3'>
				<div className='row justify-content-center'>
					<div className='col-sm-12 col-md-10 col-lg-8 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>
								<h4 className='text-center'> Alta de Cliente </h4>
							</div>
							<div className='card-body'>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div>
										<BusinessSelector />
									</div>
								)}
								<div className='container-fluid'>
									<div className='table-responsive'>
										<form onSubmit={this.edit}>{this.renderFormEdit()}</form>
									</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ClientAddComponent);
