import { Client } from '../../resources/Client';
import { Urls } from '../../resources/Urls';
import { Utils } from '../../resources/Utils';

export class LoginController {
	async getAllClient() {
		const data = await Client.GET({
			url: Urls.clientsApi.findAllActive,
			params: {
				start: 0,
				limit: 8
			}
		});

		return data;
	}

	async handleLogin(user, password) {
		const url = Urls.sessionApi.login;
		let res = {};
		if (user === '' || !user || password === '' || !password) {
			alert('Datos inválidos');
		} else {
			res = await Client.POST({
				url,
				alert: 'show',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				data: {
					identifier: user,
					password: password,
					'token-type': 2
				}
			});
		}
		const responJson = await res;
		if (responJson !== null && Object.keys(responJson).length !== 0) {
			if (responJson.tokenSecundario !== null) {
				const session = {
					token: responJson.user.tokenSecundario,
					role: responJson.user.roles[0],
					idBusiness: responJson.user.idBusiness,
					num: responJson.user.id
				};
				saveDataInSesion(session);
			} else {
				Utils.swalError('Usuario/correo o contraseña incorrectos.');
			}
		} else {
			Utils.swalError('Usuario/correo o contraseña incorrectos.');
		}
	}
}

const saveDataInSesion = (params) => {
	sessionStorage.setItem('token', params.token);
	sessionStorage.setItem('role', params.role);
	sessionStorage.setItem('idBusiness', params.idBusiness);
	sessionStorage.setItem('num', params.num);
};
