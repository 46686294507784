import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class QrFormController {
	async findAllTypes() {
		return await Client.GET({
			url: Urls.cuponsApi.getTypes
		});
	}

	async findQrBusiness(id) {
		const res = await Client.GET({
			url: Urls.qr.findBussinesById,
			alert: 'show',
			params: {
				id: id
			}
		});

		if (res.data.id !== -1) {
			let centerC = res.data.centerColor.replace(' ', '').split(',');
			centerC = centerC.map((x) => parseInt(x));

			let backC = res.data.backgroundColor.replace(' ', '').split(',');
			backC = backC.map((x) => parseInt(x));

			let cornC = res.data.cornerColor.replace(' ', '').split(',');
			cornC = cornC.map((x) => parseInt(x));

			res.data.centerColor = centerC.map((x) => parseInt(x));
			res.data.backgroundColor = backC.map((x) => parseInt(x));
			res.data.cornerColor = cornC.map((x) => parseInt(x));
			res.data.eye = parseInt(res.data.eye);
			res.data.corner = parseInt(res.data.corner);
			res.data.idBusiness = '' + res.data.idBusiness.id;
			res.data.code = '#cuponealo.com.mx#';
			res.data.platform = 'React';
			res.data.textColor = [0, 0, 0];
			res.data.titleColor = [0, 0, 0];
		}

		return res;
	}

	async insert(datos) {
		return await Client.POST({
			url: Urls.qr.saveQr,
			alert: 'show',
			data: datos
		});
	}

	async update(datos) {
		return await Client.POST({
			url: Urls.qr.updateQr,
			params: { id: parseInt(datos.idBusiness) },
			alert: 'show',
			data: datos
		});
	}

	async getPreview(datos) {
		return await fetch(Urls.qr.getImage, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(datos)
		}).then((response) => response.blob());
	}
}
