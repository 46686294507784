import { Client } from '../../resources/Client';
import { Urls } from '../../resources/Urls';

export class BirthdayController {
	async getAllClient() {
		const data = await Client.GET({
			url: Urls.clientsApi.findBirthdayDudes,
			alert: 'show',
			params: {
				page: 0,
				limit: 8
			}
		});
		return data.data;
	}

	async init() {
		const res = await Client.GET({
			url: Urls.clientsApi.findBirthdayDudes,
			alert: 'show',
			params: {
				page: 0,
				limit: 8
			}
		});
		return await res.data;
	}
}
