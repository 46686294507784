import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class CuponFormController {
	async createCoupon(data) {
		const priceD = data.price - data.price * (data.discount / 100);
		const url = Urls.cuponsApi.createCoupon;
		let descuento = '';
		let incremental = '';
		let cadena = '';
		let i = 0;
		if (data.isIncrement === true) {
			let disc = '';
			let ord = '';
			let count = 1;
			let first = '';
			for (const clave in data.discountMap) {
				if (i < data.lifes) {
					if (Object.prototype.hasOwnProperty.call(data.discountMap, clave)) {
						disc = disc + data.discountMap[clave] + ',';
						ord = ord + count.toString() + ',';
						count++;
						if (first === '') {
							first = data.discountMap[clave];
						}
					}
					i++;
				}
			}
			const cadenaDescuentos = disc.substring(0, disc.length - 1);
			const cadenaOrden = ord.substring(0, ord.length - 1);
			cadena = cadenaOrden + '*' + cadenaDescuentos + '*';
			descuento = first;
			incremental = 1;
		} else {
			descuento = data.discount;
			incremental = 0;
			cadena = '1*' + data.discount + '*';
		}
		const datas = {
			discount: descuento,
			description: data.description,
			isIncrement: incremental,
			priceDiscount: priceD,
			price: data.price,
			lifes: data.lifes,
			expirationDate: data.expirationDate,
			modifiedBy: data.num
		};
		const idBusiness = sessionStorage.getItem('idBusiness');
		const res = await Client.POST({
			url:
				url +
				'?cty=' +
				data.cty +
				'&discountS=' +
				cadena +
				'&idBusiness=' +
				idBusiness,
			alert: 'show',
			data: datas
		});
		return res;
	}

	async findLastOne() {
		const url = Urls.cuponsApi.findLastOne;
		const res = await Client.GET({
			url: url
		});
		return await res.data;
	}

	async download(code) {
		const url =
			Urls.cuponsApi.download +
			'?codeText=' +
			code +
			'&idBusiness=' +
			sessionStorage.getItem('idBusiness');
		return await Client.DOWNLOAD({ url: url });
	}

	async findQrBusiness(id) {
		const res = await Client.GET({
			url: Urls.qr.findBussinesById,
			params: {
				id: id
			}
		});

		return res;
	}
}
