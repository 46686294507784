import React, { useState } from 'react';
import '../../resources/css/General.css';

const AlertasCuponNum = () => {
	const [toggle, setToggle] = useState(false);
	return (
		<div>
			<div
				onMouseOver={() => setToggle(!toggle)}
				onMouseOut={() => setToggle(!toggle)}
				className='btn btn-primary btnAlerta'
			>
				?
			</div>

			{toggle && (
				<div className='alertaNum' style={{ height: 'auto' }}>
					<p className='letraAlerta'>Número de cupones que se van a generar.</p>
				</div>
			)}
		</div>
	);
};

export default AlertasCuponNum;
