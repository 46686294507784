import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class EmployeeGridController {
	async init(tableData) {
		let idBusiness;
		if (
			sessionStorage.getItem('role') === 'sudosu' &&
			(sessionStorage.getItem('idBusiness') === null ||
				sessionStorage.getItem('idBusiness') === undefined)
		) {
			idBusiness = 1;
		} else {
			idBusiness = sessionStorage.getItem('idBusiness');
		}

		const res = await Client.GET({
			url: Urls.usersApi.findUsers,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit,
				id: idBusiness,
				typeUser: sessionStorage.getItem('typeUser')
			}
		});
		return res;
	}

	async findAllActive(tableData) {
		const res = await Client.GET({
			url: Urls.clientsApi.findAllActive,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit
			}
		});
		return res;
	}

	async delete(id) {
		await Client.POST({
			url: Urls.cuponsApi.update,
			alert: 'show',
			params: {
				id: id
			},
			data: {
				status: '0'
			}
		});
	}

	async active(id) {
		await Client.POST({
			url: Urls.cuponsApi.update,
			alert: 'show',
			params: {
				id: id
			},
			data: {
				status: '1'
			}
		});
	}

	async edit(forme) {
		const res = await Client.POST({
			url: Urls.usersApi.update,
			alert: 'show',
			params: {
				id: forme.userVO.id,
				idBusiness: sessionStorage.getItem('idBusiness')
			},
			data: {
				name: forme.userVO.idPerson.name,
				surname: forme.userVO.idPerson.surname,
				email: forme.userVO.email,
				phone: forme.userVO.idPerson.phone,
				birthdate: forme.userVO.idPerson.birthdate,
				otherIdentifier: forme.userVO.otherIdentifier,
				typeUser: sessionStorage.getItem('typeUser')
			}
		});

		return res;
	}

	async activeUnActiveEmployee(id, idBusiness, typeUser, estado) {
		const res = Client.POST({
			url: Urls.usersApi.update,
			alert: 'show',
			params: {
				id,
				idBusiness
			},
			data: {
				status: estado,
				typeUser
			}
		});
		return res;
	}

	async insert(forme) {
		const res = await Client.POST({
			url: Urls.usersApi.insert,
			alert: 'show',
			params: {
				idBusiness: sessionStorage.getItem('idBusiness')
			},
			data: {
				name: forme.userVO.idPerson.name,
				surname: forme.userVO.idPerson.surname,
				email: forme.userVO.email,
				phone: forme.userVO.idPerson.phone,
				birthdate: forme.userVO.idPerson.birthdate,
				otherIdentifier: forme.userVO.otherIdentifier,
				typeUser: sessionStorage.getItem('typeUser')
			}
		});

		return res;
	}
}
